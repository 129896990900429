import React from 'react';
import {field, modalButton} from "../../../RU";
import AddClass from "../../../Classes/AddClass";


export default class Manual extends AddClass {
    constructor(props, state) {
        super(props, state);
        this.form = React.createRef();
    }

    render() {
        return (
            <form onSubmit={(e) => this.handleSubmit(e, ['account', 'id', 'secret', 'code'], ['account', 'id', 'secret', 'code']) }>
                <div className="modal-body">
                    <div className="annotation">
                        1. Создайте Внешнюю интеграцию в амо срм;
                        2. В поле ссылка на перенаправление укажите https://incidents.7video.org/api/amo/auth;
                        3. В поле предоставить доступ выберите: Доступ к данным аккаунта;
                        4. В полях названия и м описание введите: Инциденты;
                        5. Нажмите сохранить;
                        6. Перейдите в созданную интеграцию, откройте вкладку "Ключи и доступы", скопируйте данные в поля ниже;
                    </div>
                    <div className="form-group">
                        <label htmlFor='name'>{field.amo.account}<span style={{color: 'red'}}>*</span></label>
                        <input className="form-control" type="text" name='account' id='account' value={this.state.account ?? ''}
                               onChange={this.handleChangeText}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor='name'>{field.amo.id}<span style={{color: 'red'}}>*</span></label>
                        <input className="form-control" type="text" name='id' id='id' value={this.state.id ?? ''}
                               onChange={this.handleChangeText}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor='name'>{field.amo.secret}<span style={{color: 'red'}}>*</span></label>
                        <input className="form-control" type="text" name='secret' id='secret' value={this.state.secret ?? ''}
                               onChange={this.handleChangeText}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor='name'>{field.amo.code}<span style={{color: 'red'}}>*</span></label>
                        <input className="form-control" type="text" name='code' id='code' value={this.state.code ?? ''}
                               onChange={this.handleChangeText}/>
                    </div>
                    <div className="modal-footer">
                        <button type='button' className="btn btn-secondary" onClick={this.props.handleModal}>{modalButton.close}</button>
                        <button type="submit" ref={this.form} disabled={this.state.disabled}
                                className="btn btn-primary">{this.state.id ? modalButton.save : modalButton.add}</button>
                    </div>
                </div>
            </form>
        );
    }
}
