export const field = {
    users:{
        name: "Имя",
        email: "Email",
        password: "Пароль",
        is_admin: "Админ",
        is_active: "Активность",
        permissions: "Доступ"
    },
    make:{
        name: "Инцидент",
        text: "Текст что нужно сделать",
    },
        amo:{
        account: "Ссылка на организацию",
        id: "ID интеграции",
        secret: "Секретный ключ",
        code: 'Код авторизации'
    },
    action: 'Действия',
};

export const sidebar = {
    home: 'Настройка инцидентов',
    users: 'Пользователи',
    widget: 'Виджеты amoCrm',
    sort: "Сортировка инцидентов",
    make: "Что нужно сделать"
};


export const error = {
    users:{
        name: 'Поле имя обязательно!',
        email: 'Поле email обязательно!',
        password: 'Поле пароль обязательно!',
    },
    make:{
        text: 'Поле текст обязательно!'
    },
};

export const modalTitle = {
    users:{
        add: 'Добавление нового пользователя',
        update: 'Редактирование пользователя',
        delete: 'Удаление пользователя',
    },
    make:{
        update: 'Редактирование текста',
    },
    amo:{
        manual: 'Ручная выдача токенов'
    }

};

export const notification = {

    user: {
        add: 'Пользователь добавлен',
        edit: 'Пользователь изменен',
        move: 'Пользователь перемещен',
        delete: 'Пользователь удален',
    },
    make:{
        edit: 'Текст изменен',
        error: 'Ошибка изменения',
    },
    home: {
        save: 'Данные сохранены',
    },
    base: {
        save: 'Данные сохранены',
    },
    sort: {
        save: 'Данные сохранены',
        error: 'Ошибка сохранения',
    }

};

export const filter = {
    all: 'Выбрать все',
    clear: 'Сбросить',
    placeholder: 'Поиск...'
};

export const active = [
    {value: 0, label: 'Отключен'},
    {value: 1, label: 'Активен'},
];

export const admin = [
    {value: 0, label: 'Пользователь'},
    {value: 1, label: 'Админ'},
];

export const modalButton = {
    add: 'Добавить',
    save: 'Сохранить',
    close: 'Закрыть'
};

export const textConfirm = {
    default: 'Вы уверены?',
    textButtonConfirm: 'Да'
};
