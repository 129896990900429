import React, { useState } from 'react';
import "../login.css"
import Button from "./Button";
import AmoService from "../../../../services/AmoService";
import Modal from "../../../CoreAction/Modal";
import Manual from "./Manual";

const Login = ({amo, user, setAmo, exit}) => {

    const [newUser, setNew] = useState(0);
    const [open, setOpen] = useState(false);

    const newAuth = (e) => {
        if (e.target.checked){
            setNew(1);
        } else {
            setNew(0);
        }
    }

    const manual = async (data) => {
        try{
            const response = await AmoService.manual(data);
            if (response.data){

            }
        } catch (e) {
            console.log(e)
        } finally {

        }
    }



    return (
        <div className="login-item">
            <div className="image amo">
            </div>
            <div className="user-info">
                <div className="login">
                    <span className="table"> { amo?.account ?? 'Не подключено'} </span><br/>
                    {
                        !amo?.account ? <span className="page"><input type="checkbox" name='new' id='new' checked={newUser} onChange={newAuth}/> <label
                            htmlFor="new">Новый пользователь</label></span> : null

                    }

                </div>
                {
                    !amo.token ?
                        <div className="amo-button">
                            <Button user={user} new={newUser} client_id={amo?.client_id ?? null} setAmo={setAmo}/>
                            {/*<button className="btn btn-primary" onClick={() => setOpen(true)}>Ручная</button>*/}
                        </div> :
                        <div className="exit">
                            <button className="btn btn-danger" onClick={(e) => exit('amo')}>выйти</button>
                        </div>
                }

            </div>

            {open ?
                <Modal
                    handleModal={() => setOpen(false)}
                    type="manual"
                    components="amo"
                >
                    <Manual addItem={manual} handleModal={() => setOpen(false)} />
                </Modal> : null
            }
        </div>
    );
};

export default Login;